import request from '@/services/axios_instance'
import _ from 'lodash'
// Components
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
    components: {
        CustomDialog
    },

    props: {
        value: { type: Boolean, default: false },
        item: Object
    },

    data: () => ({
        open: false,
        role: null,
        permissions: [],
        originalPermissions: []
    }),

    watch: {
        value(newVal) {
            this.open = newVal
        },
        open(newVal) {
            this.$emit('input', newVal)
        },
        item: {
            handler: function(val) {
                this.role = val
                if (val) {
                    this.permissions = _.cloneDeep(val.permissions)
                    this.originalPermissions = _.cloneDeep(val.permissions)
                }
            },
            deep: true,
            immediate: true
        }
    },

    filters: {
        removeSlug(text) {
            return text.split('.')[0]
        }
    },

    computed: {
        does_something_changed() {
            return this.get_updated_permissions.length
        },
        title() {
            return this.role ? `${this.item.name} Permissions` : 'Role Permissions'
        },
        get_updated_permissions() {
            let toBeCheck = _.cloneDeep(this.permissions)
            return toBeCheck.filter((item, index) => {
                let slug = this.originalPermissions[index].slug
                return (
                    item.slug.view !== slug.view ||
                    item.slug.edit !== slug.edit ||
                    item.slug.update !== slug.update ||
                    item.slug.delete !== slug.delete
                )
            })
        }
    },

    methods: {
        cancel() {
            this.open = false
        },

        save() {
            if (this.validation_passed()) {
                const fields_to_save = {
                    role_id: this.item.id,
                    permissions: this.get_updated_permissions.map((i) => {
                        return {
                            id: i.id,
                            slug: i.slug,
                            description: i.description
                        }
                    })
                }
                this.$emit('update', fields_to_save)
            }
        },

        validation_passed() {
            if (this.does_something_changed) {
                return true
            } else {
                this.appSnackbar('Nothing Changed!', 'notification')
                return false
            }
        },

        clearAndClose() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.cancel() //close the modal
        },

        viewSlug(index, value) {
            if (!value) {
                this.$set(this.permissions[index], 'slug', {
                    view: false,
                    create: false,
                    update: false,
                    delete: false
                })
            }
        },

        createSlug(index, value) {
            if (value) this.$set(this.permissions[index].slug, 'view', true)
            else {
                this.$set(this.permissions[index].slug, 'update', false)
                this.$set(this.permissions[index].slug, 'delete', false)
            }
        },

        updateSlug(index, value) {
            if (value) {
                this.$set(this.permissions[index].slug, 'create', true)
                this.$set(this.permissions[index].slug, 'view', true)
            } else this.$set(this.permissions[index].slug, 'delete', false)
        },

        deleteSlug(index, value) {
            let permissions = _.cloneDeep(this.permissions)
            if (value) {
                permissions[index].slug = {
                    view: true,
                    create: true,
                    update: true,
                    delete: true
                }
                this.permissions = permissions
            }
        }
    }
}