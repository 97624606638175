
import _cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import request from '@/services/axios_instance'
//Components
import TableHeader from '@/common/TableHeader.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import GroupsDialog from './components/GroupsDialog/GroupsDialog.vue'
import PermissionsDialog from './components/PermissionsDialog/PermissionsDialog.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/Actions.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import { mapGetters } from 'vuex'

export default {
    name: 'Groups',
    mixins: [fetch_services],
    components: {
        VueTable,
        Actions,
        GroupsDialog,
        PermissionsDialog,
        TableHeader,
        DeleteDialog
    },

    data: () => ({
        group_id: null,
        paths: [
            { text: 'Settings', disabled: false, route: { name: 'settings' } },
            { text: 'Groups', disabled: true, route: null }
        ],
        headers: [
            { text: 'Group Name', value: 'group_name', width: 200 },
            { text: 'Description', value: 'description' },
            { text: 'Action', width: 200, value: 'action', sortable: false, align: 'center' }
        ],
        actions: [{
            value: 'edit_settings',
            tooltip: 'Edit Settings',
            icon: require(`@/assets/icons/groups/edit.svg`).default
        },
        {
            value: 'delete_group',
            tooltip: 'Delete Group',
            icon: require(`@/assets/icons/groups/delete.svg`).default
        },
        {
            value: '',
            tooltip: 'Key',
            icon: require(`@/assets/icons/groups/delete.svg`).default
        }
        ]
    }),

    computed: {
        ...mapGetters(['user']),
        permission() {
            return this.$_permissions.get('settings_group')
        },
        can_view() {
            if (this.user.is_admin) return true
            return this.permission && this.permission.view
        },
        can_edit() {
            if (this.user.is_admin) return true
            return this.permission && this.permission.update
        },
        can_delete() {
            if (this.user.is_admin) return true
            return this.permission && this.permission.delete
        }
    },

    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.loadItems(`api/groups/company?with_admin=true&with_permissions=true&except=order-handlers`, false, null, null)
    },

    methods: {
        update_permissions(payload) {
            request
                .put('api/roles/' + payload.role_id + '/permissions', payload)
                .then(data => {
                    this.appSnackbar(data.message, data.type)
                    this.$event.$emit('btnloading_off', false)
                })
        },

        allowedDelete(item) {
            if (item.company_id === 0) return false
            return !['admin', `${this.user.company.id}-manager`, `${this.user.company.id}-member`, `${this.user.company.id}-client`].includes(item.slug);
        },
        handleSaveGroup(payload) {
            this.addItem(`api/groups`, payload, 'Group created', () => {
                this.action_add_edit_dialog(false, null)
            }, null)
        },
        handleUpdateGroup(payload) {
            this.updateItem(`api/groups/${this.activeItem.id}`, payload, 'Group updated', () => {
                this.action_add_edit_dialog(false, null)
            }, null)
        },
        handleDeleteGroup() {
            this.deleteItem(`api/groups/${this.activeItem.id}`, 'Group deleted', () => {
                this.action_confirm_dialog(false, null)
            }, null)
        },
        handleUpdatePermission(payload) {
            this.updateItem(`api/roles/${this.activeItem.id}/permissions`, payload, 'Group permissions updated', () => {
                this.action_view_dialog(false, null)
            }, null)
        }
    }
}